import { cn } from "@/lib/utils";
import { Home, ShoppingBasket, ShoppingCart, UserCircle2 } from "lucide-react";

const baseClass =
  "inline-flex flex-col h-full items-center justify-center px-5 group hover:text-primary text-gray-700";
const baseTextClass = "text-sm transition";
const activeClass = "text-primary bg-secondary/50";
const iconClasses = "h-5 w-auto";

interface Props {
  homeLink?: string;
  homeLabel?: string;
  productsLink?: string;
}

const MobileFooter = ({ homeLabel, homeLink, productsLink }: Props) => {
  const activeLink = window.location.pathname;
  const homeLinkIsActive = activeLink === "/";
  const productLinkIsActive = activeLink.startsWith("/products");

  return (
    <footer className="fixed bottom-0 left-0 right-0 border-t border-secondary bg-white md:hidden flex items-center justify-between h-16">
      <a
        href={homeLink ? homeLink : "/"}
        className={cn(baseClass, homeLinkIsActive ? activeClass : "")}>
        <Home className={iconClasses} />
        <span className={baseTextClass}>{homeLabel ? homeLabel : "Home"}</span>
      </a>

      <a
        href={productsLink ? productsLink : "/products"}
        className={cn(baseClass, productLinkIsActive ? activeClass : "")}>
        <ShoppingBasket className={iconClasses} />
        Products
      </a>

      <a
        href="/cart"
        className={cn(baseClass, activeLink == "/cart" ? activeClass : "")}>
        <ShoppingCart className={iconClasses} />
        Cart
      </a>

      <a
        href="/dashboard"
        className={cn(
          baseClass,
          activeLink == "/dashboard" ? activeClass : ""
        )}>
        <UserCircle2 className={iconClasses} />
        Account
      </a>
    </footer>
  );
};

export default MobileFooter;
